.centered-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.table-container {
    margin: 0 auto;
}

.custom-label {
    font-size: 16px;
}
.required-label {
    color: blue;
    font-size: 16px;
    font-weight: bold;
    padding-top:15px;
}

.form-header {
    color: #54565A;
    padding-top: 8px;
    font-weight: 800;
    line-height: 24.42px;
    size: 20px;
}
.form-heading{
    border: solid 3px;
    background: #001b86;
    margin-bottom: 12px;
    text-align: center;
}


.error-message {
    color: red;
    font-size: 13px;
}
.red-border{
  width: 100%;
  box-sizing: border-box;
  border: 1px solid red;
  padding: 5px 6px; 
  margin-bottom: 10px;
  border-radius: 3px;
}

.red-borderDropdown {
    width: 100%;
    border: 1px solid red;
    border-radius: 4px;
    box-sizing: border-box;
  }  

.ucb-header {
    background-color: yellow;
}

.tableStyle {
    border: 2px solid #C0BFBD;
    border-collapse: collapse;
    width: 100%;
}

.thStyle {
    color: white;
    background-color: #7A98B6;
    border: 2px solid #C0BFBD;
    text-align: center;
}

.tdStyle {
    color: #000000;
    background-color: white;
    border: 1px solid #C0BFBD;
    text-align: center;
}

.customTdStyle {
    background-color: #A9A9A9;
    border: 1px solid #C0BFBD;
}

.H5Style {
    color: white;
    background-color: #003366;
    padding: 7px;
    margin-bottom: 50px;
}

body {
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
}
.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
}
label {
    display: block;
    font-weight: bold;
}
.input-text{
    width: 100%; 
        padding: 5px 6px; 
        margin-bottom: 10px;
        border: 1px solid #ccc;
        border-radius: 3px;
}
.row {
    display: flex;
    justify-content: space-between;
}
.react-datepicker-wrapper{
    width: 100%;
}
input:read-only {
    background-color: #b7bcbd3b;
}


.loader-wrapper {
    width: 100%;
    height: 100%;
    z-index: 1030;
    background-color: rgba(0,0,0,0.5);
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
  
    .loading-spinner {  
        position: absolute;
        width: 100%;
        top: 0px;
        left: 0px;
        height: 100vh;
        align-items: center;
        justify-content: center;
        display: flex;
        flex-direction: column;
        img {
            align-self: center;
            max-width: 90px;
            position: absolute;
            z-index: 1;
            margin-top: -8px;
          }
    }
    
  }

.gridHeader {
	background-color:#F2F2F2;
	height:30px;
	color:#000000;
	padding:10px 0px 0px 10px;
	font-weight:bold;
}
.gridHeaderHeight {
	height:40px;
	
}
.gridHeaderHeightFooterRow {
	height:40px;
	border:none;	
}
.gridHeaderFontSize {
	font-size:10px;
}
.gridHeader th {
	margin:0px;
}
.gridHeader-col1 {
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:1px solid #ffffff;
	padding-left:10px;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    font-family: inherit;
    font-style: inherit;
}
.gridHeader-col2 {
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:none;
	padding-left:10px;
    color: #666;
    font-size: 12px;
    font-weight: bold;
    font-family: inherit;
    font-style: inherit;
}
.gridHeader-col1, .gridHeader-col2 {
	height:30px;
	font-weight:bold;
	background-color:#F2F2F2;
}
.gridHeader-col1Sublevel {
	border-bottom:1px solid #ffffff;
	text-align:center;
}
.griddata-col1, .griddata-col2 {
	border-bottom:solid 1px #cccccc;
	height:50px;
	padding-left:10px;
}
.gridCenterAligh{
    text-align:center;
}
.griddataFontSize {
	font-size:10px;
}
.tablemerge {
	margin:0px;
	padding:0px
}
.inputAccountSelect {
	width:10px;
	height:5px;
	vertical-align:bottom;
}
.marginTop {
	margin-top:600px;
}
.paddingLeft100px {
	padding-left:100px;
}
.tabspacer {
	margin-left:25px;
}
.reportoutagesignin 
{
    padding-left:10px;
}
.griddataMP-col1, .griddataMP-col2 {
	height:50px;
	padding-left:10px;
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:1px solid #ffffff;
}
.gridHeaderMP-col1, .gridHeaderMP-col2 {
	height:30px;
	font-weight:bold;
	background-color:#F2F2F2;
	border-top:1px solid #D2D2D2;
	border-bottom:1px solid #D2D2D2;
	border-right:1px solid #ffffff;
	padding-left:10px;
}
.gridFooter-col1 {
	border-top:none;
	border-bottom:none;
	border-right:none;
	padding-left:10px;
}
.gridFooter-col2 {
	border-top:none;
	border-bottom:none;
	border-right:none;
	padding-left:10px;
}
.gridFooter-col1, .gridFooter-col2 {
	height:30px;
	font-weight:bold;
	background-color:#F2F2F2;
    padding-left:4px !important;
}
.griddynamicdata-col {
	border-bottom:solid 1px #cccccc;
	height:30px;
	padding-left:10px;
    color: #9e9c9c;
    font-size: 12px;
    font-family: inherit;
    font-style: inherit;
}
.accordion-body{
    padding-top: 0;
}
#collapseOne
{
    background-color: #ffffff;
    padding: 0em !important;
}
.css-13cymwt-control{
  border-radius: 0px !important;
}
.input-text{
    border-radius: 0px !important;
}
.custom-header{
    font-weight: 700 !important;
    size: 16px !important;
    line-height: 19.54px !important;
}
.custom-header-top{
    font-weight: 700 !important;
    size: 16px !important;
    line-height: 19.54px !important;
    margin-bottom: 0px !important;
}
.pt-18{
    padding-top: 18px;
}
