@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&family=Roboto:wght@300;400;500&display=swap");
body {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-color: #FFFFFF;
  font-family: "Lora", serif;
  font-family: "Roboto", sans-serif;
}

.NG-wrapper .NG-mainHeader {
  background-color: #FFFFFF;
  margin-bottom:20px;
}
.NG-wrapper .NG-mainHeader .NG-topNav {
  border-bottom: 1px solid #E0E0E0;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory {
  padding: 0;
  display: flex;
  margin-bottom: 0;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li {
  display: flex;
  border-left: 1px solid #E0E0E0;
  padding: 10px 15px;
  align-items: center;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li i {
  color: #79c3f0;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li p {
  font-size: 14px;
  font-weight: 700;
  list-style: none;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 0;
  padding-left: 5px;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li p span {
  display: block;
  font-size: 10px;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.42px;
  font-family: "Roboto", sans-serif;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory li:last-child {
  border-right: 1px solid #E0E0E0;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-pointer {
  color: #79c3f0;
  font-size: 24px;
  margin: 0px 0px 4px 16px;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-active {
  border-top: 2px solid #79c3f0;
}
.NG-wrapper .NG-mainHeader .NG-topNav .NG-businessCagegory .NG-active p {
  font-weight: 700;
}
.NG-wrapper .NG-fixedMenu {
  padding-top: 12px;
  border-bottom: 1px solid #E0E0E0;
  box-shadow: 0px 3px 10px #E0E0E0;
}
.NG-wrapper .NG-fixedMenu .NG-collapse {
  margin-left: 120px;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link span {
  font-family: "Roboto", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.42px;
  color: #001B86;
  max-width: 100px;
  text-align: center;
  margin: 0px 8px;
  height: 40px;
  display: flex;
  align-items: center;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .nav-link i {
  text-align: center;
  color: #E0E0E0;
  display: block;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-greenBTN {
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 0px;
  background-color: #218808;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.34px;
  height: 44px;
  margin-top: 10px;
  margin-right: 15px;
  padding: 14px 16px;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-greenBTN i {
  color: #FFFFFF;
  margin-right: 5px;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-blueBTN {
  color: #FFFFFF;
  border: none;
  outline: none;
  border-radius: 0px;
  background-color: #001B86;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.34px;
  height: 44px;
  margin-top: 10px;
  padding: 14px 24px;
}
.NG-wrapper .NG-fixedMenu .NG-collapse .NG-navNav .nav-item .NG-blueBTN i {
  color: #FFFFFF;
  margin-right: 5px;
}
.NG-wrapper .breadcrumb .NG-Obreadcrumb {
  padding: 15px 20px 0 5px;
}
.NG-wrapper .breadcrumb .NG-Obreadcrumb .breadcrumb-item a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  text-decoration: none;
  color: #001B86;
}
.NG-wrapper .breadcrumb .NG-Obreadcrumb .active {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  text-decoration: none;
  color: #9E9E9E;
  margin-top: 4px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion {
  border: none;
  min-width: 265px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.42px;
  background-color: transparent;
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed::after {
  background-image: none;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .collapsed:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed) {
  width: 244px;
  height: 44px;
  color: #001B86;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.42px;
  position: relative;
  background-color: #E1E1E1;
  box-shadow: inset 0 0px 0 rgba(0, 0, 0, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed):hover {  
  border-left: 4px solid #00aff0; 
 }
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed)::after {
  background-image: none;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 0px solid white;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed)::before {
  content: "";
  position: absolute;
  right: -20px;
  bottom: 0;
  width: 0;
  height: 0;
  border-left: 20px solid #E1E1E1;
  border-top: 24px solid transparent;
  border-bottom: 20px solid transparent;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-headeAccordion .NG-accordionHeader .accordion-button:not(.collapsed):focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem {
  border: 0px solid rgba(0, 0, 0, 0);
  background-color: transparent;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body {
  padding-top: 0px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body h2 {
  color: #54565A;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  margin-bottom: 27px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body label {
  color: #54565A;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.54px;
  letter-spacing: 0.34px;
  margin-bottom: 15px;  
  size: 16px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body p {
  color: #54565A;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.34px;
  margin-bottom: 15px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form {
  padding: 32px;
  /* margin-top: 56px; */
  background-color: #FFFFFF;
}
/* .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form h4 {
  color: #54565A;
  font-size: 17px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.34px;
  margin-bottom: 32px;
} */
/* .NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet {
  margin-bottom: 32px;
} */
.NG-radiobutton-format{
  padding-top: 12px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet label {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: inline-block;
  margin-bottom: 5px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-forminput {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #54565A;
  padding-left: 15px;
  padding-right: 15px;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  border: 2px solid #D8D8D8;
  background-color: #FFFFFF;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-forminput:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-formSelect {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #54565A;
  font-style: normal;
  line-height: normal;
  border: 2px solid #D8D8D8;
  background-color: #FFFFFF;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-formSelect:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet small {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet small span {
  color: #001B86;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-input {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  border: 2px solid #D8D8D8;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSet .NG-flexyBox .form-check .form-check-label {
  color: #54565A;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-block;
  margin: 12px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN {
  justify-content: end;
  display: flex;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs {
  border: 0;
  outline: 0;
  font-size: 16px;
  font-weight: 700;
  color: #FFFFFF;
  font-style: normal;
  text-align: center;
  line-height: normal;
  border-radius: 0;
  padding: 15px 32px;
  letter-spacing: 0.32px;
  background-color: #001B86;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-form .NG-fieldSetBTN .NG-mainBTNs:focus {
  box-shadow: 0 0 0 0rem rgba(13, 110, 253, 0);
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer {
  margin-top: 30px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer .NG-disclamer {
  font-size: 12px;
  color: #54565A;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 5px;
}
.NG-wrapper .NG-accordion .NG-accordion .NG-accordionItem .accordion-collapse .accordion-body .NG-fileDisclamer .NG-disclamer strong {
  font-size: 12px;
  color: #54565A;
  font-style: italic;
  font-weight: 600;
  line-height: normal;
}

.NG-fieldSet{
padding-top: 15px !important;
}
.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0.5rem;
  box-sizing: content-box;
}

.custom-table {
  width: 100%!important;
  border: 4px solid #d8d8d8;
}

.custom-table th {
  color: #53565a;
  font-family: Helvetica Neue,Helvetica,Arial Nova,Arial,sans-serif;
    font-weight: 700;
    background-color: #d8d8d8;
}

.custom-table tr {
  border-bottom: 4px solid #d8d8d8;
  background-color: #ffffff;
}
.custom-table tbody tr td {
  background-color: #ffffff;
}
.custom-table tfoot tr td {
  background-color: #f2f2f2;
}
.backtoselection{
  color: #001b86 !important;
  background-color: #d8d8d8 !important;
  border-color: #d8d8d8 !important;
  border-radius: 0px !important;
}
a.backtoselection {
  text-decoration: none !important;
}
.excelBtn{
  color: #ffffff !important;
  background-color: #001b86 !important;
  border-color: #001b86 !important;
  border-radius: 0px !important;
  float: right;
  width: 186px;
  margin-left: 8px;
  height: 36px;
}
a.no-href {
  color: blue;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}
a.no-href:hover, a:focus {
  color: #e78c07;
  text-decoration: none;
}